<template>
    <div class="Confirmhexiao">
        <div @click="verificationOrder">
            <p class="Confirmhexiao-top">确认核销</p>
        </div>
        <div class="Confirmhexiao-wrap">
            <ul class="noOrder-wrap-bottom-1">
                <li>
                    <p>
                        <svg t="1598241119684" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="6806" width="16" height="16">
                            <path
                                d="M501.333333 501.333333c-117.333333 0-213.333333-96-213.333333-213.333333s96-213.333333 213.333333-213.333333 213.333333 96 213.333334 213.333333-96 213.333333-213.333334 213.333333z m0-384c-93.866667 0-170.666667 76.8-170.666666 170.666667s76.8 170.666667 170.666666 170.666667 170.666667-76.8 170.666667-170.666667-76.8-170.666667-170.666667-170.666667zM900.266667 917.333333l-49.066667-362.666666-211.2-23.466667v42.666667l172.8 19.2v4.266666l36.266667 277.333334H174.933333l36.266667-277.333334v-4.266666l172.8-19.2v-42.666667L172.8 554.666667 123.733333 917.333333z"
                                fill p-id="6807" />
                            <path d="M256 640h128v42.666667h-128z" fill="#c4c4c4" p-id="6808" />
                        </svg>
                    </p>
                    <div>
                        <p>姓 名:</p>
                        <input type="text" disabled="disabled" :value="detail.address_contacts" />
                    </div>
                </li>
                <li>
                    <p>
                        <svg t="1598248560791" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="7651" width="16" height="16">
                            <path
                                d="M819.2 204.8V102.4a51.2 51.2 0 0 0-51.2-51.2H256a51.2 51.2 0 0 0-51.2 51.2v102.4h614.4z m0 51.2H204.8v665.6a51.2 51.2 0 0 0 51.2 51.2h512a51.2 51.2 0 0 0 51.2-51.2V256zM256 0h512a102.4 102.4 0 0 1 102.4 102.4v819.2a102.4 102.4 0 0 1-102.4 102.4H256a102.4 102.4 0 0 1-102.4-102.4V102.4a102.4 102.4 0 0 1 102.4-102.4z m179.2 844.8h153.6a25.6 25.6 0 0 1 0 51.2h-153.6a25.6 25.6 0 0 1 0-51.2z"
                                p-id="7652" />
                        </svg>
                    </p>
                    <div>
                        <p>手机号:</p>
                        <input type="text" disabled="disabled" :value="detail.address_phone" />
                    </div>
                </li>
            </ul>
            <div class="tabbar">
                <div class="tabbar-all">
                    <div class="tabbar-all-top" v-if="false">
                        <div class="tabbar-all-top-l">
                            <div style="font-size: 0.3rem;">订单号：{{detail.order_no}}</div>
                        </div>
                        <div class="tabbar-all-top-r">
                            <!-- <p>待付款</p> -->
                        </div>
                    </div>
                    <div class="tabbar-all-center">
                        <img :src="detail.goods_image" alt />
                        <div class="tabbar-all-c-r">
                            <p>{{detail.goods_name}}</p>
                            <div class="tabbar-all-c-r-b">
                                <p>￥{{detail.goods_price}}</p>
                                <p>x1</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="noOrder-wrap-bottom-5">
                <p class="p6">订单信息</p>
                <div class="bottom-1">
                    <p>订单编号：</p>
                    <p>{{detail.order_no}}</p>
                </div>
                <div class="bottom-2">
                    <p>下单时间：</p>
                    <p>{{detail.create_time}}</p>
                </div>
                <div class="bottom-3">
                    <p>付款时间：</p>
                    <p>{{detail.pay_time}}</p>
                </div> 
                <template v-if="detail.reserve_info">
                    <div class="bottom-3">
                        <p>预约日期：</p>
                        <p>{{detail.reserve_info.reserve_date}} </p>
                    </div>
                    <div class="bottom-3">
                        <p>预约时段：</p>
                        <p>{{detail.reserve_info.reserve_time}}</p>
                    </div>
                    <div class="bottom-3">
                        <p>预约门店：</p>
                        <p>{{detail.reserve_info.reserve_shop_name}}</p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        verificationOrderDetail,
        verificationOrder
    } from "@/http/api.js";
    export default {
        data() {
            return {
                detail: {},
                shopUserInfo: {},
                order_id: '',
                order_cards_id: '',
                isPost: false
            };
        },

        beforeCreate() {
            if (!this.$LStorage.getItem("shopUserInfo")) {
                this.$router.replace({
                    path: "/merchatlogin"
                });
            }
        },

        mounted() {
            let query = this.$route.query
            if (query.order_id && query.order_cards_id) {
                this.order_id =  query.order_id
                this.order_cards_id = query.order_cards_id
                this.shopUserInfo = this.$LStorage.getItem("shopUserInfo");
                this.verificationOrderDetail();
            } else {
                this.$dialog.alert({
                    message: '核销订单页面参数异常,返回上一页看看',
                }).then(() => {
                    this.$router.go(-1);
                });
            }
        },
        methods: {
            // 获取详情
            async verificationOrderDetail() {
                const res = await verificationOrderDetail({
                    data: {
                        order_id: this.order_id,
                        order_cards_id: this.order_cards_id
                    }
                }); 
                this.detail = res.data;
            },
            //核销
            async verificationOrder() {
                if (this.isPost) {
                    this.$toast.fail("请勿重复提交");
                } else {
                    this.isPost = true;
                    const res = await verificationOrder({
                        data: {
                            order_id: this.order_id,
                            order_cards_id: this.order_cards_id,
                            shop_id: this.shopUserInfo.shop_id,
                            shop_user_id: this.shopUserInfo.shop_user_id
                        }
                    });
                    console.log(res);
                    if (res.code == 200) {
                        let that = this;
                        setTimeout(() => {
                            that.$router.replace({
                                path: "/hexiaosucess"
                            });
                        }, 1000);
                    } else {
                        this.isPost = false;
                        this.$toast(res.msgs);
                    }
                }
            }
        }
    };
</script>

<style lang="scss" scope>
    .Confirmhexiao {
        width: 100%;
        min-height: 100vh;
        background: #f6f6f6;

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .Confirmhexiao-top {
            width: calc(100% - 0.48rem);
            height: 0.95rem;
            margin: 0 auto;
            margin-top: 0.28rem;
            background: #e14f2a;
            color: #fff;
            border-radius: 0.08rem;
            font-size: 0.28rem;
            text-align: center;
            line-height: 0.95rem;
        }

        .Confirmhexiao-wrap {
            width: calc(100% - 0.48rem);
            margin: 0 auto;

            .noOrder-wrap-bottom-1 {
                // width: calc(100% - 0.48rem);
                background: #fff;
                border-radius: 0.08rem;
                margin: 0 auto;
                margin-bottom: 0.25rem;
                margin-top: 0.5rem;

                li {
                    height: 1.07rem;
                    display: flex;
                    border-bottom: 1px solid #f2f2f2;
                    align-items: center;
                    color: #333333;
                    padding-left: 0.25rem;
                    box-sizing: border-box;

                    &:last-child {
                        border-bottom: none;
                    }

                    svg {
                        display: block;
                        margin-right: 0.15rem;
                    }

                    div {
                        display: flex;
                        align-items: center;

                        p {
                            font-size: 0.27rem;
                        }

                        input {
                            width: 4.8rem;
                            height: 1.07rem;
                            margin-left: 0.12rem;
                            font-size: 0.23rem;
                        }
                    }
                }
            }

            .tabbar {
                width: 100%;
                height: 100%;
                background: #f6f6f6;

                .tabbar-all {
                    width: 100%;
                    background: #fff;
                    margin-bottom: 0.3rem;
                    // border-bottom: 1px solid #eeeeee;

                    .tabbar-all-top {
                        height: 0.82rem;
                        display: flex;
                        align-items: center;
                        padding-right: 0.13rem;
                        box-sizing: border-box;

                        .tabbar-all-top-l {
                            display: flex;
                            align-items: center;
                            padding-left: 0.1rem;
                            box-sizing: border-box;

                            p:nth-child(1) {
                                width: 0.36rem;
                                height: 0.36rem;
                                border-radius: 0.04rem;
                                font-size: 0.1rem;
                                color: #ffff;
                                background: #000;
                            }

                            p:nth-child(2) {
                                font-size: 0.27rem;
                                margin-left: 0.1rem;
                                margin-right: 0.1rem;
                            }
                        }

                        .tabbar-all-top-r {
                            font-size: 0.26rem;
                            color: #e50012;
                            margin-left: 3.14rem;
                        }
                    }

                    .tabbar-all-center {
                        width: 100%;
                        display: flex;
                        padding: 0.2rem 0.1rem 0.2rem 0.1rem;
                        box-sizing: border-box;
                        // border-bottom: 1px solid #eeeeee;

                        img {
                            width: 2.46rem;
                            height: 1.79rem;
                        }

                        .tabbar-all-c-r {
                            margin-left: 0.2rem;
                            width: calc(100% - 2.66rem);

                            p:nth-child(1) {
                                width: 100%;
                                font-size: 0.26rem;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }

                            .tabbar-all-c-r-b {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                font-size: 0.23rem;
                                margin-top: 1.1rem;

                                p:nth-child(1) {
                                    color: #eb676c;
                                }

                                p:nth-child(2) {
                                    color: #888888;

                                }
                            }
                        }
                    }
                }
            }

            .noOrder-wrap-bottom-5 {
                // width: calc(100% - 0.48rem);
                margin: 0 auto;
                margin-top: 0.2rem;
                padding: 0.23rem 0 0.3rem 0.13rem;
                box-sizing: border-box;
                background: #fff;

                .p6 {
                    font-size: 0.27rem;
                    border-left: 7px solid #e40011;
                    padding-left: 0.08rem;
                    box-sizing: border-box;
                    padding-top: 0.05rem;
                }

                .bottom-1 {
                    display: flex;
                    font-size: 0.23rem;
                    color: #484848;
                    padding-top: 0.32rem;
                    padding-left: 0.13rem;
                    box-sizing: border-box;
                }

                .bottom-2 {
                    display: flex;
                    font-size: 0.23rem;
                    color: #484848;
                    padding-top: 0.32rem;
                    padding-left: 0.13rem;
                    box-sizing: border-box;
                }

                .bottom-3 {
                    display: flex;
                    font-size: 0.23rem;
                    color: #484848;
                    padding-top: 0.32rem;
                    padding-left: 0.13rem;
                    box-sizing: border-box;
                }
            }
        }
    }
</style>
